import { useContext } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LanguageContext } from "../../../../languagesContext";
import { languages } from "../../../../languages";

const Span = () => <span></span>;

const SecWhoWeContant = () => {
  const {language} = useContext(LanguageContext)
  const selectedLanguage = languages[language]
  return (
    <div className="col-12 col-lg-6">
      <div className="who-we-contant">
        <div className="dream-dots" data-aos="fade-up">
          {Array(7)
            .fill()
            .map((item,key) => (
              <Span key={key}/>
            ))}
        </div>
        <h4 data-aos="fade-up">
          {selectedLanguage.SecWhoWeContant.Charting}
        </h4>
        <p data-aos="fade-up">
            {selectedLanguage.SecWhoWeContant.AtCoin}
        </p>
        <Link to="about-us" className=" mt-30">
          <button className="btn dream-btn">
            {selectedLanguage.All.ReadMore}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default SecWhoWeContant;
