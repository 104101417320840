import { useContext } from "react";
import SectionHeading from "../../../components/SectionHeading";
import { languages } from "../../../languages";
import Content from "./Content";
import { LanguageContext } from "../../../languagesContext";

const SecOurServices = ({ data }) => {
  const {language} = useContext(LanguageContext)
  const selectedLanguage = languages[language]
  return (
    <section
      className="our_services_area section-padding-100-70 clearfix"
      id="services"
    >
      <div className="container">
        <SectionHeading
          title={selectedLanguage.SecOurServices.Title}
          text={selectedLanguage.SecOurServices.Text}
        />

        <div className="row">
          {data &&
            data.map((item, key) => (
              <Content
                key={key}
                img={item.img}
                title={item.title}
                text={item.text}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default SecOurServices;
