const BlogPost = ({text}) => {

  return (
    <div className="col-12" data-aos="fade-up">
        <div className="single-blog-area">
            <div className="blog-content">
                <p>{text}</p>
            </div>
        </div>
    </div>
  );
}

export default BlogPost;