import SectionHeading from '../../../components/SectionHeading'

import BlogPost from './BlogPost'

const SecBlog = (props) => {
  return (
    <section className="our_blog_area clearfix" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <SectionHeading
                        title={props.heading}
                    />
                </div>
            </div>
            <img src={props.img}/>
            <div className="row justify-content-center">
                {props.paragraph && props.paragraph.map((item , key) => (
                    <BlogPost
                        key={key}
                        text={item}
                    />
                ))}
            </div>
        </div>
    </section>
  );
}

export default SecBlog;