import Footer from "../layouts/FooterPages";
import Header from "../layouts/Header";
import "../template/HomeDemo1/style/HomeDemo1.scss";
import { MarketsPosts } from "../data/data-containers/data-HomeDemo1";
import SecBlog from "../template/HomeDemo1/SecBlog";
import { useContext } from "react";
import { LanguageContext } from "../languagesContext";
const Markets = () => {
  const {language} = useContext(LanguageContext)
  return (
    <div style={{ marginTop: "100px" }}>
      <Header />
      {MarketsPosts &&
        MarketsPosts[language].map((post,index) => (
          <SecBlog heading={post.heading} paragraph={post.paragraph} img={post.img} key={index}/>
        ))}
      <Footer />
    </div>
  );
};
export default Markets;
