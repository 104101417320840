import SecIco from "../SecIco";
import { FooterLogo } from "../../../utils/allImgs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useContext } from "react";
import { LanguageContext } from "../../../languagesContext";
import { languages } from "../../../languages";
const SecContent = () => {
  const {language} = useContext(LanguageContext)
  const selectedLanguage = languages[language]
  return (
    <div className="col-12">
      {/* Content Info */}
      <div className="contact_info_area d-flex justify-content-around">
        <SecIco logo={FooterLogo} />
        <div
          className="contact_info text-center wow fadeInUp"
          data-wow-delay="0.2s"
        >
          <h5>{selectedLanguage.All.Navigate}</h5>
          <Link to="/about-us">
            <a href>
              <p>{selectedLanguage.All.AboutUs}</p>
            </a>
          </Link>
          <Link to="/markets">
            <a href>
              <p>{selectedLanguage.All.Markets}</p>
            </a>
          </Link>
          <Link to="/pricing">
            <a href>
              <p>{selectedLanguage.All.Pricing}</p>
            </a>
          </Link>
        </div>
        {/* Content Info */}
        <div
          className="contact_info text-center wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <h5>{selectedLanguage.All.Account}</h5>
          <Link to="/login">
            <a href>
              <p>{selectedLanguage.All.Login}</p>
            </a>
          </Link>
          <Link to="/signup">
            <a href>
              <p>{selectedLanguage.All.SignUp}</p>
            </a>
          </Link>
        </div>
        {/* Content Info */}
      </div>
    </div>
  );
};

export default SecContent;
