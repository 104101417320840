import { useContext } from 'react'
import SectionHeading from '../../../components/SectionHeading'
import { languages } from '../../../languages'
import { LanguageContext } from '../../../languagesContext'
import ServiceBlock from './ServiceBlock'

const SecOurFeatures = ({data}) => {
    const {language} = useContext(LanguageContext)
    const selectedLanguage = languages[language]
  return (
    <section className="features section-padding-100" id='pricing'>
        <SectionHeading
            title={selectedLanguage.All.OurPricingPlans}
        />
        <div className="container-fluid">
            <div className="row">
                    {data && data.map((item , key) => (
                        <ServiceBlock
                            key={key}
                            classIco={item.classIco}
                            title={item.title}
                            items={item.items}
                        />
                    ))}
            </div>
        </div>
    </section>
  );
}

export default SecOurFeatures;