import Footer from "../layouts/FooterPages"
import SecContact from "../layouts/FooterPages/SecContact"
import Header from "../layouts/Header"

const Signin = ()=>{
    return (
        <div style={{marginTop:"120px"}}>
            <Header/>
            <SecContact />
            <Footer/>
        </div>
    )
}
export default Signin