import "./style/HomeDemo1.scss";

import {
  service_single_content,
  timelineInfo,
  ServiceBlock,
} from "../../data/data-containers/data-HomeDemo1.js";

import { HomeDemo1Solution } from "../../utils/allImgs";

import Header from "../../layouts/Header";
import Footer from "../../layouts/FooterPages";

import SecWelcomeArea from "./SecWelcomeArea";
import SecAboutUs from "./SecAboutUs";
import SecOurServices from "./SecOurServices";
import SecOurRoadmap from "./SecOurRoadmap";
import SecOurFeatures from "./SecOurFeatures";
import { useContext } from "react";
import { LanguageContext } from "../../languagesContext.js";

let Landing = () => {
  const {language} = useContext(LanguageContext)
  return (
    <>
      <Header />
      <div className="HomeDemo1">
        <SecWelcomeArea />
        <SecAboutUs img={HomeDemo1Solution} />
        <SecOurServices data={service_single_content[language]} />
        <SecOurRoadmap data={timelineInfo[language]} />
        <SecOurFeatures data={ServiceBlock[language]} />
      </div>
      <Footer />
    </>
  );
};

export default Landing;
