const Timeline = ({ timelineClass, title, text }) => {
  return (
    <div className={timelineClass}>
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  );
};

export default Timeline;
