
import './footer.css'

import SecContent from './SecContent'

const Footer = () => {
    return (
      <footer className="footer-area bg-img">
        <div className="footer-content-area " style={{background:"#603F26"}}>
          <div className="container">
            <div className="row align-items-end">
              <SecContent />
            </div>
          </div>
        </div>
      </footer>
    );
};

export default Footer