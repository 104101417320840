const SecIco = ({logo}) => {
	return(
        <div className="">
          <div className="footer-copywrite-info">
            {/* Copywrite */}
            <div className="copywrite_text wow fadeInUp" data-wow-delay="0.2s">
              <div className="">
                <img src={logo} alt="logo" style={{width:'120px'}}/>
              </div>
            </div>
          </div>
        </div>
	)
}

export default SecIco