import { useContext, useEffect } from "react";

import { Logo } from "../../data/data-layout/data-Header.js";

import { Addshrink, addActiveClass, OpenMenu, moveSmooth } from "../../utils/";

import "./header.css";

import Preloader from "../../components/Preloader";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../languagesContext.js";
import { languages } from "../../languages.js";
const Header = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  const selectedLanguage = languages[language];

  useEffect(() => {
    const locale = localStorage.getItem("locale");
    if (locale) {
      setLanguage(locale);
    }
  }, []);
  useEffect(() => {
    Addshrink();
  }, []);

  useEffect(() => {
    OpenMenu();
  }, []);

  useEffect(() => {
    moveSmooth();
  }, []);

  return (
    <>
      <Preloader />
      <header className="header-area wow fadeInDown" data-wow-delay="0.2s">
        <div className="classy-nav-container breakpoint-off">
          <div className="container">
            <nav
              className="classy-navbar justify-content-between"
              id="dreamNav"
            >
              <Link to="/">
                <img src={Logo} alt="logo" style={{ width: "180px" }} />
              </Link>
              <div className="classy-navbar-toggler">
                <span className="navbarToggler" onClick={addActiveClass}>
                  <span />
                  <span />
                  <span />
                </span>
              </div>
              <div className="classy-menu">
                <div className="classycloseIcon">
                  <div className="cross-wrap" onClick={addActiveClass}>
                    <span className="top" />
                    <span className="bottom" />
                  </div>
                </div>
                <div className="classynav">
                  <ul id="nav">
                    <Link to="/">
                      <li>
                        <a onClick={moveSmooth} href="#home">
                          {selectedLanguage.All.Home}
                        </a>
                      </li>
                    </Link>
                    <Link to="/about-us">
                      <li>
                        <a onClick={moveSmooth} href="#about">
                          {selectedLanguage.All.About}
                        </a>
                      </li>
                    </Link>
                    <Link to="/markets">
                      <li>
                        <a onClick={moveSmooth} href="#markets">
                          {selectedLanguage.All.Markets}
                        </a>
                      </li>
                    </Link>
                    <Link to="/pricing">
                      <li>
                        <a onClick={moveSmooth} href="#pricing">
                          {selectedLanguage.All.Pricing}
                        </a>
                      </li>
                    </Link>
                    <Link to="/signup">
                      <li>
                        <a onClick={moveSmooth} href="#signup">
                          {selectedLanguage.All.Register}
                        </a>
                      </li>
                    </Link>
                  </ul>

                  <div className="d-flex gap-2 " style={{ marginLeft: "5px" }}>
                    <div
                      className="cursor-pointer"
                      onClick={() => setLanguage("French")}
                      style={{ margin: "0px 10px", cursor: "pointer" }}
                    >
                      <svg
                        viewBox="0 0 36 36"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--twemoji"
                        preserveAspectRatio="xMidYMid meet"
                        fill="#000000"
                        width="30"
                        height="30"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            fill="#ED2939"
                            d="M36 27a4 4 0 0 1-4 4h-8V5h8a4 4 0 0 1 4 4v18z"
                          ></path>
                          <path
                            fill="#002495"
                            d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5H4z"
                          ></path>
                          <path fill="#EEE" d="M12 5h12v26H12z"></path>
                        </g>
                      </svg>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => setLanguage("English")}
                      style={{ cursor: "pointer" }}
                    >
                      <svg
                        viewBox="0 0 36 36"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--twemoji"
                        preserveAspectRatio="xMidYMid meet"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            fill="#00247D"
                            d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z"
                          ></path>
                          <path
                            fill="#CF1B2B"
                            d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z"
                          ></path>
                          <path
                            fill="#EEE"
                            d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z"
                          ></path>
                          <path
                            fill="#CF1B2B"
                            d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div
                      className="cursor-pointer ml-2"
                      onClick={() => setLanguage("Deutsch")}
                      style={{ cursor: "pointer" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 36 36"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--twemoji"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          fill="#FFCD05"
                          d="M0 27a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4v-4H0v4z"
                        />
                        <path fill="#ED1F24" d="M0 14h36v9H0z" />
                        <path
                          fill="#141414"
                          d="M32 5H4a4 4 0 0 0-4 4v5h36V9a4 4 0 0 0-4-4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <Link to="login">
                    <a
                      href="login"
                      className="btn login-btn"
                      style={{ marginLeft: "20px" }}
                    >
                      {selectedLanguage.All.Login}
                    </a>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
