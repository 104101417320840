import { useContext } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LanguageContext } from "../../../../languagesContext";
import { languages } from "../../../../languages";

import "../../style/HomeDemo1.scss"
const ServiceBlock = ({ title, items }) => {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = languages[language];
  return (
    <div
      className="services-block-four col-lg-3 col-10"
      style={{ margin: "5px auto", display: "flex", flexDirection: "column" }}
    >
      <h3>{title}</h3>
      <ul>
        {items.map((item) => (
          <li
            key={item}
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="#5356FF"
              className="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
            </svg>
            <span style={{ maxWidth: "80%" }}>{item}</span>
          </li>
        ))}
      </ul>
      <Link
        to="signup"
        style={{
          marginTop: "auto",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom:"20px"
        }}
      >
        <button className="btn dream-btn">
          {selectedLanguage.All.JoinNow}
        </button>
      </Link>
    </div>
  );
};

export default ServiceBlock;
