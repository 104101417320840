import React, { useEffect } from "react";
import Aos from "aos";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "aos/dist/aos.css";
import "./assets/css/General.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css";
import Signin from "./pages/Signin";
import Services from "./pages/Services";
import Markets from "./pages/Markets";
import Pricing from "./pages/Pricing";
import Signup from "./pages/Signup";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import Landing from "./template/HomeDemo1";
import About from "./pages/About";
import { LanguageProvider } from "./languagesContext";
const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="App">
      <LanguageProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/login" component={Signin} />
            <Route path="/about-us" component={About} />
            <Route path="/services" component={Services} />
            <Route path="/markets" component={Markets} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/signup" component={Signup} />
          </Switch>
        </BrowserRouter>
        <ToastContainer />
      </LanguageProvider>
    </div>
  );
};

export default App;
