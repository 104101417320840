import {
  HomeDemo1IcoPlatforms1,
  HomeDemo1IcoPlatforms2,
  HomeDemo1IcoPlatforms3,
  HomeDemo1IcoPlatforms4,
  HomeDemo1IcoPlatforms5,
  HomeDemo1IcoPlatforms6,
  HomeDemo1Services1,
  HomeDemo1Services2,
  HomeDemo1Services3,
  HomeDemo1TokenIcon1,
  HomeDemo1TeamImg1,
  HomeDemo1TeamImg2,
  HomeDemo1TeamImg3,
  HomeDemo1TeamImg4,
  HomeDemo1Partners1,
  HomeDemo1Partners2,
  HomeDemo1Partners3,
  HomeDemo1Partners4,
  HomeDemo1Partners5,
  HomeDemo1Partners6,
  HomeDemo1Partners7,
  HomeDemo1Partners8,
  HomeDemo1Partners9,
  HomeDemo1Partners10,
  HomeDemo1Partners11,
  HomeDemo1Partners12,
  HomeDemo1BlogImg1,
  HomeDemo1BlogImg2,
  HomeDemo1BlogImg3,
} from "../../utils/allImgs";
import forexBg from "../../assets/img/forexBg.jpg";
import stocksBg from "../../assets/img/stocksBg.jpg";
import futuresBg from "../../assets/img/futuresBg.jpg";
import cfdsBg from "../../assets/img/cfdsBg.jpg";
import cryptoBg from "../../assets/img/cryptoBg.jpg";
import commoditiesBg from "../../assets/img/commoditiesBg.jpg";
export const VerticalSocial = [
  { nameIco: "fa fa-telegram" },
  { nameIco: "fa fa-medium" },
  { nameIco: "fa fa-facebook" },
  { nameIco: "fa fa-twitter" },
  { nameIco: "fa fa-github" },
  { nameIco: "fa fa-instagram" },
  { nameIco: "fa fa-linkedin" },
];

export const SingleCoolFact = [
  {
    img: HomeDemo1IcoPlatforms1,
    ico_check: true,
  },
  {
    img: HomeDemo1IcoPlatforms2,
    ico_check: false,
  },
  {
    img: HomeDemo1IcoPlatforms3,
    ico_check: true,
  },
  {
    img: HomeDemo1IcoPlatforms4,
    ico_check: true,
  },
  {
    img: HomeDemo1IcoPlatforms5,
    ico_check: false,
  },
  {
    img: HomeDemo1IcoPlatforms6,
    ico_check: true,
  },
];

export const service_single_content = {
  English: [
    {
      img: HomeDemo1Services1,
      title: "Personalized Investment Consultancy ",
      text: "Get tailored investment advice aligned with your goals, risk tolerance, and timeline. Our experts help optimize portfolios for maximum returns and minimal risk.",
    },
    {
      img: HomeDemo1Services2,
      title: "Comprehensive Market Analysis ",
      text: "Stay informed with our detailed market analysis. We provide insights into trends, opportunities, and risks to aid your investment decisions.",
    },
    {
      img: HomeDemo1Services3,
      title: "Portfolio Management Solutions: ",
      text: "Optimize your investments with our proactive portfolio management. We monitor markets, adjust allocations, and rebalance portfolios to meet your objectives.",
    },
  ],
  French: [
    {
      img: HomeDemo1Services1,
      title: "Conseil en investissement personnalisé",
      text: "Obtenez des conseils en investissement sur mesure, alignés sur vos objectifs, votre tolérance au risque et votre échéancier. Nos experts vous aident à optimiser vos portefeuilles pour des rendements maximums et des risques minimes.",
    },
    {
      img: HomeDemo1Services2,
      title: "Analyse de marché complète",
      text: "Restez informé grâce à notre analyse détaillée du marché. Nous fournissons des informations sur les tendances, les opportunités et les risques pour vous aider dans vos décisions d'investissement.",
    },
    {
      img: HomeDemo1Services3,
      title: "Solutions de gestion de portefeuille",
      text: "Optimisez vos investissements avec notre gestion proactive de portefeuille. Nous surveillons les marchés, ajustons les allocations et rééquilibrons les portefeuilles pour répondre à vos objectifs.",
    },
  ],
  Deutsh: [
    {
      img: "HomeDemo1Services1",
      title: "Persönliche Anlageberatung",
      text: "Erhalten Sie maßgeschneiderte Anlageberatung, die auf Ihre Ziele, Ihre Risikotoleranz und Ihren Zeitplan abgestimmt ist. Unsere Experten helfen bei der Optimierung von Portfolios für maximale Rendite und minimales Risiko.",
    },
    {
      img: "HomeDemo1Services2",
      title: "Umfassende Marktanalyse",
      text: "Bleiben Sie mit unserer detaillierten Marktanalyse auf dem Laufenden. Wir bieten Einblicke in Trends, Chancen und Risiken, um Sie bei Ihren Anlageentscheidungen zu unterstützen.",
    },
    {
      img: "HomeDemo1Services3",
      title: "Portfolio-Management-Lösungen",
      text: "Optimieren Sie Ihre Investitionen mit unserem proaktiven Portfoliomanagement. Wir überwachen Märkte, passen Allokationen an und gleichen Portfolios neu aus, um Ihre Ziele zu erreichen.",
    },
  ],
};

export const timelineInfo = {
  English: [
    {
      timelineClass: "block block-left",
      title: "Forex",
      text: "Navigate global currency markets with precision for optimal trading outcomes.",
    },
    {
      timelineClass: "block block-right mt-30",
      title: "Stocks",
      text: "Invest in leading companies with confidence using our expert insights and analysis.",
    },
    {
      timelineClass: "block block-left mt-30",
      title: "Commodities",
      text: "Diversify portfolios with tangible assets, capitalizing on commodity market fluctuations.",
    },
    {
      timelineClass: "block block-right mt-30",
      title: "Futures",
      text: "Unlock opportunities in futures trading with our strategic guidance and risk management expertise.",
    },
    {
      timelineClass: "block block-left mt-30",
      title: "Crypto",
      text: "Enter the dynamic world of cryptocurrencies with comprehensive analysis and strategic advice.",
    },
    {
      timelineClass: "block block-right mt-30",
      title: "CFDs",
      text: "Trade diverse assets with leverage, benefiting from price movements without owning the underlying asset.",
    },
  ],
  French: [
    {
      timelineClass: "block block-left",
      title: "Forex",
      text: "Naviguez dans les marchés mondiaux des devises avec précision pour des résultats de trading optimaux.",
    },
    {
      timelineClass: "block block-right mt-30",
      title: "Actions",
      text: "Investissez dans les principales entreprises en toute confiance en utilisant nos analyses et insights d'experts.",
    },
    {
      timelineClass: "block block-left mt-30",
      title: "Matières premières",
      text: "Diversifiez les portefeuilles avec des actifs tangibles, en tirant parti des fluctuations du marché des matières premières.",
    },
    {
      timelineClass: "block block-right mt-30",
      title: "Contrats à terme",
      text: "Débloquez des opportunités dans le trading de contrats à terme avec notre guidance stratégique et notre expertise en gestion des risques.",
    },
    {
      timelineClass: "block block-left mt-30",
      title: "Crypto",
      text: "Plongez dans le monde dynamique des crypto-monnaies avec une analyse complète et des conseils stratégiques.",
    },
    {
      timelineClass: "block block-right mt-30",
      title: "CFDs",
      text: "Commercez des actifs diversifiés avec effet de levier, en profitant des mouvements de prix sans posséder l'actif sous-jacent.",
    },
  ],
  Deutsch: [
    {
      timelineClass: "block block-left",
      title: "Forex",
      text: "Navigieren Sie präzise auf den globalen Devisenmärkten, um optimale Handelsergebnisse zu erzielen.",
    },
    {
      timelineClass: "block block-right mt-30",
      title: "Aktien",
      text: "Investieren Sie mit Zuversicht in führende Unternehmen und nutzen Sie dabei die Erkenntnisse und Analysen unserer Experten.",
    },
    {
      timelineClass: "block block-left mt-30",
      title: "Rohstoffe",
      text: "Diversifizieren Sie Ihre Portfolios mit Sachwerten und nutzen Sie die Schwankungen der Rohstoffmärkte.",
    },
    {
      timelineClass: "block block-right mt-30",
      title: "Futures",
      text: "Erschließen Sie Chancen im Futures-Handel mit unserer strategischen Beratung und unserem Fachwissen im Risikomanagement.",
    },
    {
      timelineClass: "block block-left mt-30",
      title: "Krypto",
      text: "Betreten Sie die dynamische Welt der Kryptowährungen mit umfassender Analyse und strategischer Beratung.",
    },
    {
      timelineClass: "block block-right mt-30",
      title: "CFDs",
      text: "Handeln Sie verschiedene Vermögenswerte mit Hebelwirkung und profitieren Sie von Preisbewegungen, ohne den zugrunde liegenden Vermögenswert zu besitzen.",
    },
  ],
};

export const ServiceBlock = {
  English: [
    {
      classIco: "icon ti-mobile",
      title: "Starter Plan - $250 USD",
      items: [
        "Access to basic market analysis reports",
        "Limited consultation hours per month",
        "Educational resources and beginner-friendly tools",
        "Email support",
      ],
    },
    {
      classIco: "icon ti-widget",
      title: "Trader Plan - $500 to $1000 USD",
      items: [
        "Comprehensive market analysis and trade recommendations",
        "Increased consultation hours for personalized guidance",
        "Advanced trading tools and indicators",
        "Priority email and chat support",
        "Exclusive webinars and educational materials",
      ],
    },
    {
      classIco: "icon ti-settings",
      title: "Advanced Plan - $5000+ USD",
      items: [
        "Premium market insights with detailed research reports",
        "Unlimited consultation with dedicated account manager",
        "Access to exclusive trading algorithms and strategies",
        "Priority access to customer support via phone, email, and chat",
        "VIP invitations to industry events and seminars",
      ],
    },
  ],
  French: [
    {
      classIco: "icon ti-mobile",
      title: "Plan de démarrage - 250 $ USD",
      items: [
        "Accès aux rapports d'analyse de marché de base",
        "Nombre limité d'heures de consultation par mois",
        "Ressources éducatives et outils adaptés aux débutants",
        "Support par e-mail",
      ],
    },
    {
      classIco: "icon ti-widget",
      title: "Plan Trader - 500 à 1000 $ USD",
      items: [
        "Analyse de marché complète et recommandations de trading",
        "Augmentation des heures de consultation pour un accompagnement personnalisé",
        "Outils de trading avancés et indicateurs",
        "Support prioritaire par e-mail et chat",
        "Webinaires exclusifs et matériaux éducatifs",
      ],
    },
    {
      classIco: "icon ti-settings",
      title: "Plan Avancé - 5000 $ USD et plus",
      items: [
        "Perspectives de marché premium avec rapports de recherche détaillés",
        "Consultation illimitée avec gestionnaire de compte dédié",
        "Accès à des algorithmes et des stratégies de trading exclusifs",
        "Accès prioritaire au support client via téléphone, e-mail et chat",
        "Invitations VIP à des événements et séminaires de l'industrie",
      ],
    },
  ],
  Deutsch: [
    {
      classIco: "icon ti-mobile",
      title: "Starterplan - 250 $ USD",
      items: [
        "Zugang zu grundlegenden Marktanalysen",
        "Begrenzte Beratungszeiten pro Monat",
        "Bildungsmaterialien und benutzerfreundliche Tools für Anfänger",
        "E-Mail-Support",
      ],
    },
    {
      classIco: "icon ti-widget",
      title: "Trader-Plan - 500 bis 1000 $ USD",
      items: [
        "Umfassende Marktanalyse und Handelsempfehlungen",
        "Erhöhte Beratungszeiten für persönliche Betreuung",
        "Erweiterte Handelswerkzeuge und Indikatoren",
        "Priorisierter E-Mail- und Chat-Support",
        "Exklusive Webinare und Bildungsmaterialien",
      ],
    },
    {
      classIco: "icon ti-settings",
      title: "Erweitertes Plan - 5000 $ USD und mehr",
      items: [
        "Premium-Markteinblicke mit detaillierten Forschungsberichten",
        "Unbegrenzte Beratung durch dedizierten Kontomanager",
        "Zugang zu exklusiven Handelsalgorithmen und -strategien",
        "Prioritärer Zugang zum Kundensupport per Telefon, E-Mail und Chat",
        "VIP-Einladungen zu Branchenveranstaltungen und Seminaren",
      ],
    },
  ],
};

export const SocialListIco = [
  { icoName: "fa fa-facebook-square" },
  { icoName: "fa fa-twitter-square" },
  { icoName: "fa fa-github-square" },
  { icoName: "fa fa-instagram" },
  { icoName: "fa fa-medium" },
];

export const FQAInfo = {
  English: [
    {
      text: "What are the objectives of this token?",
      ID: "Q1",
    },
    {
      text: "What is Token Sale and pre-sale?",
      ID: "Q2",
    },
    {
      text: "What is the pre-sale start date?",
      ID: "Q3",
    },
    {
      text: "how may I take part in pre-sale?",
      ID: "Q4",
    },
  ],
  French: [
    {
      text: "Quels sont les objectifs de ce jeton ?",
      ID: "Q1",
    },
    {
      text: "Qu'est-ce que la vente de jetons et la prévente ?",
      ID: "Q2",
    },
    {
      text: "Quelle est la date de début de la prévente ?",
      ID: "Q3",
    },
    {
      text: "Comment puis-je participer à la prévente ?",
      ID: "Q4",
    },
  ],
  Deutsch: [
    {
      text: "Was sind die Ziele dieses Tokens?",
      ID: "Q1",
    },
    {
      text: "Was ist ein Token-Verkauf und Vorverkauf?",
      ID: "Q2",
    },
    {
      text: "Was ist das Startdatum des Vorverkaufs?",
      ID: "Q3",
    },
    {
      text: "Wie kann ich am Vorverkauf teilnehmen?",
      ID: "Q4",
    },
  ],
};

export const DocElementTitle = {
  English: [
    {
      title: "WhitePaper",
    },
    {
      title: "OnePager",
    },
    {
      title: "Rating Review",
    },
    {
      title: "Marketing Plan",
    },
  ],
  French: [
    {
      title: "Livre blanc",
    },
    {
      title: "Document de présentation",
    },
    {
      title: "Analyse de notation",
    },
    {
      title: "Plan marketing",
    },
  ],
  Deutsch: [
    {
      title: "Whitepaper",
    },
    {
      title: "Einseiter",
    },
    {
      title: "Bewertungsbericht",
    },
    {
      title: "Marketingplan",
    },
  ],
};

export const TokenText = {
  English: [
    {
      text: "Lorem ipsum dolor sit amet, conse ctetur elit",
      img: HomeDemo1TokenIcon1,
    },
    {
      text: "Sed quis accumsan nisi Ut ut felis",
      img: HomeDemo1TokenIcon1,
    },
    {
      text: "felis congue nisl hendrerit commodo",
      img: HomeDemo1TokenIcon1,
    },
    {
      text: "arch nemo sequi rem saepe ad quasi ullam.",
      img: HomeDemo1TokenIcon1,
    },
  ],
  French: [
    {
      text: "Lorem ipsum dolor sit amet, consectetur elit",
      img: "HomeDemo1TokenIcon1",
    },
    {
      text: "Sed quis accumsan nisi. Ut ut felis",
      img: "HomeDemo1TokenIcon1",
    },
    {
      text: "Felis congue nisl hendrerit commodo",
      img: "HomeDemo1TokenIcon1",
    },
    {
      text: "Architecto nemo sequi rem saepe ad quasi ullam.",
      img: "HomeDemo1TokenIcon1",
    },
  ],
  Deutsch: [
    {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      img: HomeDemo1TokenIcon1,
    },
    {
      text: "Sed quis accumsan nisi. Ut ut felis",
      img: HomeDemo1TokenIcon1,
    },
    {
      text: "Felis congue nisl, hendrerit commodo",
      img: HomeDemo1TokenIcon1,
    },
    {
      text: "Architekt nemo sequi rem saepe ad quasi ullam.",
      img: HomeDemo1TokenIcon1,
    },
  ],
};

export const MissionData = [
  {
    icoName: "ti-shine",
    title: "Quality",
  },
  {
    icoName: "ti-ruler-pencil",
    title: "Creativity",
  },
  {
    icoName: "ti-heart",
    title: "Reliability",
  },
];

export const TeamMember = [
  {
    img: HomeDemo1TeamImg1,
    title: "Sunny khan",
    text: "Executive Officer",
  },
  {
    img: HomeDemo1TeamImg2,
    title: "Ajoy Das",
    text: "Business Development",
  },
  {
    img: HomeDemo1TeamImg3,
    title: "Afroza Mou",
    text: "UX/UI Designer",
  },
  {
    img: HomeDemo1TeamImg4,
    title: "Lim Sarah",
    text: "Head of Marketing",
  },
  {
    img: HomeDemo1TeamImg1,
    title: "Sunny khan",
    text: "Executive Officer",
  },
  {
    img: HomeDemo1TeamImg2,
    title: "Ajoy Das",
    text: "Business Development",
  },
  {
    img: HomeDemo1TeamImg3,
    title: "Afroza Mou",
    text: "UX/UI Designer",
  },
  {
    img: HomeDemo1TeamImg4,
    title: "Lim Sarah",
    text: "Head of Marketing",
  },
];

export const PartnersData = [
  { img: HomeDemo1Partners1 },
  { img: HomeDemo1Partners2 },
  { img: HomeDemo1Partners3 },
  { img: HomeDemo1Partners4 },
  { img: HomeDemo1Partners5 },
  { img: HomeDemo1Partners6 },
  { img: HomeDemo1Partners7 },
  { img: HomeDemo1Partners8 },
  { img: HomeDemo1Partners9 },
  { img: HomeDemo1Partners10 },
  { img: HomeDemo1Partners11 },
  { img: HomeDemo1Partners12 },
];

export const BlogPost = [
  { img: HomeDemo1BlogImg1 },
  { img: HomeDemo1BlogImg2 },
  { img: HomeDemo1BlogImg3 },
];

export const AboutUsPosts = {
  English: {
    heading: "About us",
    paragraph: [
      `With over two decades of unwavering commitment to excellence, Eurobitx stands as a beacon of reliability and expertise in the world of financial markets. Since our inception, we have continuously evolved, leveraging our 20 years of experience to navigate the complexities of the trading landscape with precision and foresight.`,
      `Our journey began with a simple yet ambitious vision: to empower traders of all levels with the knowledge and tools needed to achieve their financial goals. Over the years, we have diligently honed our craft, cultivating a deep understanding of market dynamics, trends, and opportunities. This wealth of experience forms the cornerstone of our success, guiding our clients towards informed decisions and profitable outcomes.`,
      `At Eurobitx, success is not merely measured by numbers but by the trust and confidence bestowed upon us by our clients. We take pride in our track record of delivering tangible results, consistently exceeding expectations and setting new standards of excellence in the industry. Our commitment to transparency, integrity, and client satisfaction has earned us the loyalty of traders worldwide.`,
      `As we embark on the next chapter of our journey, we remain steadfast in our dedication to empowering traders to unlock their full potential. With our unparalleled expertise, innovative solutions, and unwavering commitment to excellence, we continue to pave the way for a future where financial success is within reach for all who dare to dream. Join us as we redefine the possibilities of trading and embark on a journey towards greater prosperity together.`,
    ],
  },
  French: {
    heading: "À propos de nous",
    paragraph: [
      "Avec plus de deux décennies d'engagement inébranlable envers l'excellence, Eurobitx se présente comme un phare de fiabilité et d'expertise dans le monde des marchés financiers. Depuis notre création, nous avons évolué en continu, tirant parti de nos 20 ans d'expérience pour naviguer avec précision et prévoyance dans la complexité du paysage commercial.",
      "Notre voyage a commencé avec une vision simple mais ambitieuse : donner aux traders de tous niveaux les connaissances et les outils nécessaires pour atteindre leurs objectifs financiers. Au fil des ans, nous avons perfectionné notre métier avec diligence, cultivant une compréhension profonde des dynamiques, des tendances et des opportunités du marché. Cette richesse d'expérience constitue la pierre angulaire de notre succès, guidant nos clients vers des décisions éclairées et des résultats rentables.",
      "Chez Eurobitx, le succès ne se mesure pas uniquement en chiffres, mais par la confiance et la confiance que nos clients nous accordent. Nous sommes fiers de notre historique de résultats tangibles, dépassant constamment les attentes et établissant de nouvelles normes d'excellence dans l'industrie. Notre engagement envers la transparence, l'intégrité et la satisfaction du client nous a valu la fidélité des traders du monde entier.",
      "Alors que nous entamons le prochain chapitre de notre voyage, nous restons fermes dans notre détermination à habiliter les traders à libérer leur plein potentiel. Avec notre expertise inégalée, nos solutions innovantes et notre engagement inébranlable envers l'excellence, nous continuons à ouvrir la voie à un avenir où le succès financier est à la portée de tous ceux qui osent rêver. Rejoignez-nous alors que nous redéfinissons les possibilités du trading et entreprenons ensemble un voyage vers une plus grande prospérité.",
    ],
  },
  Deutsch: {
    heading: "Über uns",
    paragraph: [
      "Mit über zwei Jahrzehnten unerschütterlichem Engagement für Exzellenz steht Eurobitx als Leuchtturm der Zuverlässigkeit und Expertise in der Welt der Finanzmärkte. Seit unserer Gründung haben wir uns kontinuierlich weiterentwickelt und unsere 20-jährige Erfahrung genutzt, um mit Präzision und Voraussicht die Komplexitäten der Handelslandschaft zu navigieren.",
      "Unsere Reise begann mit einer einfachen, aber ehrgeizigen Vision: Händlern aller Niveaus das Wissen und die Werkzeuge zu geben, um ihre finanziellen Ziele zu erreichen. Im Laufe der Jahre haben wir unser Handwerk gewissenhaft verfeinert und ein tiefes Verständnis für Marktdynamiken, Trends und Chancen entwickelt. Diese Fülle an Erfahrung bildet das Fundament unseres Erfolgs und führt unsere Kunden zu informierten Entscheidungen und profitablen Ergebnissen.",
      "Bei Eurobitx wird Erfolg nicht nur an Zahlen gemessen, sondern auch am Vertrauen und der Zuversicht, die unsere Kunden in uns setzen. Wir sind stolz auf unsere Erfolgsbilanz, die ständig die Erwartungen übertrifft und neue Maßstäbe in der Branche setzt. Unser Engagement für Transparenz, Integrität und Kundenzufriedenheit hat uns die Treue von Händlern auf der ganzen Welt eingebracht.",
      "Während wir das nächste Kapitel unserer Reise beginnen, bleiben wir fest entschlossen, Händler dabei zu unterstützen, ihr volles Potenzial auszuschöpfen. Mit unserer beispiellosen Expertise, innovativen Lösungen und unerschütterlichem Engagement für Exzellenz setzen wir weiterhin Maßstäbe für eine Zukunft, in der finanzieller Erfolg für alle, die es wagen zu träumen, in Reichweite liegt. Begleiten Sie uns, während wir die Möglichkeiten des Handels neu definieren und gemeinsam eine Reise zu größerem Wohlstand antreten.",
    ],
  },
};

export const MarketsPosts = {
  English: [
    {
      heading: "Forex",
      paragraph: [
        "The Forex market, also known as the foreign exchange market, is the largest and most liquid financial market in the world. It involves the buying and selling of currencies, with daily trading volumes exceeding trillions of dollars. Forex trading allows participants to capitalize on fluctuations in currency values, driven by factors such as economic indicators, geopolitical events, and central bank policies.",
        "At Eurobitx, we offer a comprehensive suite of Forex trading services tailored to meet the needs of traders at all levels. Our platform provides access to a wide range of currency pairs, including major, minor, and exotic pairs, enabling traders to diversify their portfolios and seize opportunities across different regions and economies. With our expert analysis and real-time market data, traders can make informed decisions and execute trades with confidence.",
        "Our team of experienced analysts brings decades of expertise to the table, providing insights into currency trends, technical analysis, and risk management strategies. Whether you're a novice trader or an experienced investor, we offer the tools, resources, and support needed to succeed in the Forex market. Join us and unlock the potential of Forex trading to achieve your financial goals.",
      ],
      img: forexBg,
    },
    {
      heading: "Stocks",
      paragraph: [
        "The stock market, also known as the equity market, is a vital component of the global financial system where ownership stakes in publicly traded companies are bought and sold. It offers opportunities for investors to participate in the growth and success of companies by purchasing shares of stock. Stock prices are influenced by factors such as company performance, industry trends, and macroeconomic conditions.",
        "At Eurobitx, we provide a comprehensive range of stock trading services designed to empower investors to build and manage their investment portfolios. Our platform offers access to a diverse selection of stocks from around the world, spanning various industries and sectors. With our expert analysis and research reports, investors can identify promising investment opportunities and make informed decisions.",
        "Our team of seasoned analysts brings decades of experience to the table, offering insights into market dynamics, trading strategies, and risk management techniques. Whether you're a novice investor or an experienced trader, we offer the support and guidance needed to navigate the complexities of the stock market with confidence. Join us and embark on a journey of wealth creation through stock market investing.",
      ],
      img: stocksBg,
    },
    {
      heading: "Commodities",
      paragraph: [
        "Commodity markets involve the buying and selling of physical goods such as gold, oil, and agricultural products. They play a crucial role in the global economy, providing essential raw materials for industries and serving as a hedge against inflation and economic uncertainty. Commodity prices are influenced by factors such as supply and demand dynamics, geopolitical events, and weather conditions.",
        "At Eurobitx, we offer a comprehensive range of commodity trading services designed to help traders capitalize on market opportunities and manage risk effectively. Our platform provides access to a diverse range of commodities, including precious metals, energy products, and agricultural commodities, enabling traders to build well-diversified portfolios.",
        "Our team of experienced analysts brings decades of expertise to the table, offering insights into commodity market trends, trade recommendations, and risk management strategies. Whether you're a novice trader or an experienced investor, we provide the tools, resources, and support needed to succeed in the dynamic world of commodity trading. Join us and unlock the potential of commodity markets to achieve your financial goals.",
      ],
      img: commoditiesBg,
    },
    {
      heading: "CFDs",
      paragraph: [
        "Contracts for Difference (CFDs) are derivative financial instruments that allow traders to speculate on price movements in various financial markets without owning the underlying assets. They offer flexibility and leverage, making them popular among traders who seek short-term trading opportunities and portfolio diversification. CFD prices are derived from the underlying asset's value and are traded on margin.",
        "At Eurobitx, we offer a comprehensive range of CFD trading services designed to meet the diverse needs of traders. Our platform provides access to a wide range of CFD products, including stocks, indices, currencies, commodities, and cryptocurrencies, enabling traders to diversify their portfolios and capitalize on market opportunities.",
        "Our team of experienced professionals brings decades of expertise to the table, offering insights into market trends, trading strategies, and risk management techniques. Whether you're a novice trader or an experienced investor, we offer the support and guidance needed to navigate the complexities of CFD trading effectively. Join us and unlock the potential of CFD markets to achieve your financial goals.",
      ],
      img: cfdsBg,
    },
    {
      heading: "Futures",
      paragraph: [
        "Futures markets are where participants trade standardized contracts to buy or sell an asset at a predetermined price on a specified future date. These contracts typically include commodities like oil, gold, and agricultural products, as well as financial instruments such as stock indices and currencies. Futures trading allows investors to hedge against price fluctuations and speculate on future price movements.",
        "At Eurobitx, we offer a comprehensive suite of futures trading services designed to meet the needs of traders and investors. Our platform provides access to a wide range of futures contracts, spanning various asset classes and markets. With our advanced trading tools and real-time market data, traders can capitalize on market opportunities and manage risk effectively.",
        "Our team of experienced professionals brings decades of expertise to the table, offering insights into market trends, trading strategies, and risk management techniques. Whether you're a novice trader or an experienced investor, we offer the support and guidance needed to navigate the complexities of futures trading effectively. Join us and unlock the potential of futures markets to achieve your financial goals.",
      ],
      img: futuresBg,
    },
    {
      heading: "Crypto",
      paragraph: [
        "Crypto markets are where participants trade various cryptocurrencies, including Bitcoin, Ethereum, and altcoins. These markets operate 24/7 and offer opportunities for traders and investors to buy, sell, and exchange digital assets. The crypto market is known for its high volatility and potential for significant price movements, presenting both risks and opportunities for participants.",
        "At Eurobitx, we provide a comprehensive range of services tailored to the needs of crypto traders and investors. Our platform offers access to a wide variety of cryptocurrencies, allowing users to trade pairs such as BTC/USD, ETH/BTC, and many others. With our intuitive trading interface and advanced analytical tools, traders can navigate the crypto market efficiently and execute their strategies effectively.",
        "Backed by a team of experienced professionals deeply immersed in the crypto industry, we offer valuable insights into market dynamics, trading techniques, and risk management strategies. Whether you're a newcomer to the crypto space or a seasoned investor, we offer the support and resources needed to succeed in the fast-paced world of crypto trading. Join us and tap into the potential of crypto markets to achieve your financial aspirations.",
      ],
      img: cryptoBg,
    },
  ],
  French: [
    {
      heading: "Forex",
      paragraph: [
        "Le marché des changes, également connu sous le nom de marché des changes, est le marché financier le plus important et le plus liquide au monde. Il implique l'achat et la vente de devises, avec des volumes de trading quotidiens dépassant les billions de dollars. Le trading sur le Forex permet aux participants de capitaliser sur les fluctuations des valeurs des devises, alimentées par des facteurs tels que les indicateurs économiques, les événements géopolitiques et les politiques des banques centrales.",
        "Chez Eurobitx, nous proposons une gamme complète de services de trading sur le Forex conçus pour répondre aux besoins des traders de tous niveaux. Notre plateforme offre un accès à une large gamme de paires de devises, y compris les principales, les mineures et les exotiques, permettant aux traders de diversifier leurs portefeuilles et de saisir les opportunités dans différentes régions et économies. Avec notre analyse experte et nos données de marché en temps réel, les traders peuvent prendre des décisions éclairées et exécuter des transactions en toute confiance.",
        "Notre équipe d'analystes expérimentés apporte des décennies d'expertise à la table, fournissant des informations sur les tendances des devises, l'analyse technique et les stratégies de gestion des risques. Que vous soyez un trader novice ou un investisseur expérimenté, nous offrons les outils, les ressources et le soutien nécessaires pour réussir sur le marché du Forex. Rejoignez-nous et débloquez le potentiel du trading sur le Forex pour atteindre vos objectifs financiers.",
      ],
      img: forexBg,
    },
    {
      heading: "Actions",
      paragraph: [
        "La bourse, également connue sous le nom de marché des actions, est un élément vital du système financier mondial où des participations dans des sociétés cotées en bourse sont achetées et vendues. Elle offre des opportunités aux investisseurs de participer à la croissance et au succès des entreprises en achetant des actions. Les prix des actions sont influencés par des facteurs tels que la performance de l'entreprise, les tendances sectorielles et les conditions macroéconomiques.",
        "Chez Eurobitx, nous fournissons une gamme complète de services de trading sur actions conçus pour permettre aux investisseurs de constituer et de gérer leurs portefeuilles d'investissement. Notre plateforme offre un accès à une sélection diversifiée d'actions du monde entier, couvrant divers secteurs et industries. Avec notre analyse experte et nos rapports de recherche, les investisseurs peuvent identifier des opportunités d'investissement prometteuses et prendre des décisions éclairées.",
        "Notre équipe d'analystes chevronnés apporte des décennies d'expérience à la table, offrant des informations sur la dynamique du marché, les stratégies de trading et les techniques de gestion des risques. Que vous soyez un investisseur novice ou un trader expérimenté, nous offrons le soutien et les conseils nécessaires pour naviguer avec confiance dans la complexité du marché boursier. Rejoignez-nous et lancez-vous dans un voyage de création de richesse grâce à l'investissement en bourse.",
      ],
      img: stocksBg,
    },
    {
      heading: "Matières premières",
      paragraph: [
        "Les marchés des matières premières impliquent l'achat et la vente de biens physiques tels que l'or, le pétrole et les produits agricoles. Ils jouent un rôle crucial dans l'économie mondiale, fournissant des matières premières essentielles pour les industries et servant de protection contre l'inflation et l'incertitude économique. Les prix des matières premières sont influencés par des facteurs tels que la dynamique de l'offre et de la demande, les événements géopolitiques et les conditions météorologiques.",
        "Chez Eurobitx, nous proposons une gamme complète de services de trading sur matières premières conçus pour aider les traders à capitaliser sur les opportunités de marché et à gérer efficacement les risques. Notre plateforme offre un accès à une large gamme de matières premières, y compris les métaux précieux, les produits énergétiques et les produits agricoles, permettant aux traders de constituer des portefeuilles bien diversifiés.",
        "Notre équipe d'analystes expérimentés apporte des décennies d'expertise à la table, offrant des informations sur les tendances du marché des matières premières, les recommandations de trading et les stratégies de gestion des risques. Que vous soyez un trader novice ou un investisseur expérimenté, nous fournissons les outils, les ressources et le soutien nécessaires pour réussir dans le monde dynamique du trading de matières premières. Rejoignez-nous et débloquez le potentiel des marchés des matières premières pour atteindre vos objectifs financiers.",
      ],
      img: commoditiesBg,
    },
    {
      heading: "CFDs",
      paragraph: [
        "Les contrats de différence (CFD) sont des instruments financiers dérivés qui permettent aux traders de spéculer sur les mouvements de prix dans divers marchés financiers sans posséder les actifs sous-jacents. Ils offrent flexibilité et effet de levier, les rendant populaires parmi les traders à la recherche d'opportunités de trading à court terme et de diversification de portefeuille. Les prix des CFD sont dérivés de la valeur de l'actif sous-jacent et sont négociés avec marge.",
        "Chez Eurobitx, nous proposons une gamme complète de services de trading sur CFD conçus pour répondre aux besoins diversifiés des traders. Notre plateforme offre un accès à une large gamme de produits CFD, notamment des actions, des indices, des devises, des matières premières et des crypto-monnaies, permettant aux traders de diversifier leurs portefeuilles et de capitaliser sur les opportunités de marché.",
        "Notre équipe de professionnels expérimentés apporte des décennies d'expertise à la table, offrant des informations sur les tendances du marché, les stratégies de trading et les techniques de gestion des risques. Que vous soyez un trader novice ou un investisseur expérimenté, nous offrons le soutien et les conseils nécessaires pour naviguer efficacement dans la complexité du trading sur CFD. Rejoignez-nous et débloquez le potentiel des marchés des CFD pour atteindre vos objectifs financiers.",
      ],
      img: cfdsBg,
    },
    {
      heading: "Futures",
      paragraph: [
        "Les marchés à terme sont des marchés où les participants échangent des contrats normalisés pour acheter ou vendre un actif à un prix prédéterminé à une date future spécifiée. Ces contrats comprennent généralement des matières premières telles que le pétrole, l'or et les produits agricoles, ainsi que des instruments financiers tels que les indices boursiers et les devises. Le trading à terme permet aux investisseurs de se couvrir contre les fluctuations de prix et de spéculer sur les mouvements de prix futurs.",
        "Chez Eurobitx, nous proposons une gamme complète de services de trading sur futures conçus pour répondre aux besoins des traders et des investisseurs. Notre plateforme offre un accès à une large gamme de contrats à terme, couvrant diverses classes d'actifs et marchés. Avec nos outils de trading avancés et nos données de marché en temps réel, les traders peuvent capitaliser sur les opportunités de marché et gérer efficacement les risques.",
        "Notre équipe de professionnels expérimentés apporte des décennies d'expertise à la table, offrant des informations sur les tendances du marché, les stratégies de trading et les techniques de gestion des risques. Que vous soyez un trader novice ou un investisseur expérimenté, nous offrons le soutien et les conseils nécessaires pour naviguer efficacement dans la complexité du trading sur futures. Rejoignez-nous et débloquez le potentiel des marchés à terme pour atteindre vos objectifs financiers.",
      ],
      img: futuresBg,
    },
    {
      heading: "Crypto",
      paragraph: [
        "Les marchés des crypto-monnaies sont des endroits où les participants échangent différentes crypto-monnaies, notamment Bitcoin, Ethereum et altcoins. Ces marchés fonctionnent 24/7 et offrent des opportunités aux traders et aux investisseurs d'acheter, de vendre et d'échanger des actifs numériques. Le marché des crypto-monnaies est connu pour sa grande volatilité et son potentiel de mouvements de prix significatifs, présentant à la fois des risques et des opportunités pour les participants.",
        "Chez Eurobitx, nous proposons une gamme complète de services adaptés aux besoins des traders et des investisseurs en crypto-monnaies. Notre plateforme offre un accès à une grande variété de crypto-monnaies, permettant aux utilisateurs de trader des paires telles que BTC/USD, ETH/BTC et bien d'autres. Avec notre interface de trading intuitive et nos outils analytiques avancés, les traders peuvent naviguer efficacement sur le marché des crypto-monnaies et exécuter leurs stratégies de manière efficace.",
        "Soutenu par une équipe de professionnels expérimentés profondément immergés dans l'industrie des crypto-monnaies, nous offrons des insights précieux sur la dynamique du marché, les techniques de trading et les stratégies de gestion des risques. Que vous soyez nouveau dans l'espace crypto ou un investisseur expérimenté, nous offrons le soutien et les ressources nécessaires pour réussir dans le monde rapide du trading de crypto-monnaies. Rejoignez-nous et exploitez le potentiel des marchés de crypto-monnaies pour atteindre vos aspirations financières.",
      ],
      img: cryptoBg,
    },
  ],
  Deutsch: [
    {
      heading: "Forex",
      paragraph: [
        "Der Devisenmarkt, auch bekannt als der Devisenmarkt, ist der größte und liquideste Finanzmarkt der Welt. Er beinhaltet den Kauf und Verkauf von Währungen, wobei die täglichen Handelsvolumina Billionen von Dollar übersteigen. Der Devisenhandel ermöglicht es den Teilnehmern, von Schwankungen der Währungswerte zu profitieren, die durch Faktoren wie wirtschaftliche Indikatoren, geopolitische Ereignisse und die Politik der Zentralbanken beeinflusst werden.",
        "Bei Eurobitx bieten wir eine umfassende Palette von Forex-Handelsdienstleistungen an, die auf die Bedürfnisse von Tradern auf allen Ebenen zugeschnitten sind. Unsere Plattform bietet Zugang zu einer breiten Palette von Währungspaaren, einschließlich der Haupt-, Neben- und exotischen Paare, was es Tradern ermöglicht, ihre Portfolios zu diversifizieren und Chancen in verschiedenen Regionen und Volkswirtschaften zu nutzen. Mit unserer Expertenanalyse und Echtzeit-Marktdaten können Trader fundierte Entscheidungen treffen und Trades selbstbewusst ausführen.",
        "Unser Team erfahrener Analysten bringt jahrzehntelange Expertise mit, die Einblicke in Währungstrends, technische Analyse und Risikomanagementstrategien bietet. Egal, ob Sie ein Einsteiger-Trader oder ein erfahrener Investor sind, wir bieten die Werkzeuge, Ressourcen und Unterstützung, die für den Erfolg auf dem Forex-Markt erforderlich sind. Begleiten Sie uns und entdecken Sie das Potenzial des Forex-Handels, um Ihre finanziellen Ziele zu erreichen.",
      ],
      img: forexBg,
    },
    {
      heading: "Aktien",
      paragraph: [
        "Die Börse, auch bekannt als der Aktienmarkt, ist eine wichtige Komponente des globalen Finanzsystems, in dem Beteiligungen an börsennotierten Unternehmen gekauft und verkauft werden. Sie bietet Investitionsmöglichkeiten, um am Wachstum und Erfolg von Unternehmen teilzunehmen, indem Aktien gekauft werden. Aktienkurse werden von Faktoren wie der Unternehmensleistung, Branchentrends und makroökonomischen Bedingungen beeinflusst.",
        "Bei Eurobitx bieten wir eine umfassende Palette von Aktienhandelsdienstleistungen, die darauf abzielen, Investoren zu ermöglichen, ihre Anlageportfolios aufzubauen und zu verwalten. Unsere Plattform bietet Zugang zu einer vielfältigen Auswahl an Aktien aus der ganzen Welt, die verschiedene Branchen und Sektoren abdecken. Mit unserer Expertenanalyse und Forschungsberichten können Investoren vielversprechende Anlagechancen identifizieren und fundierte Entscheidungen treffen.",
        "Unser Team erfahrener Analysten bringt jahrzehntelange Erfahrung mit, um Einblicke in Marktdynamik, Handelsstrategien und Risikomanagementtechniken zu bieten. Egal, ob Sie ein Einsteiger-Investor oder ein erfahrener Trader sind, wir bieten die Unterstützung und Anleitung, die benötigt werden, um die Komplexitäten des Aktienmarktes mit Zuversicht zu bewältigen. Begleiten Sie uns und starten Sie eine Reise zur Vermögensbildung durch Aktienmarktinvestitionen.",
      ],
      img: stocksBg,
    },
    {
      heading: "Rohstoffe",
      paragraph: [
        "Die Rohstoffmärkte beinhalten den Kauf und Verkauf physischer Güter wie Gold, Öl und landwirtschaftliche Produkte. Sie spielen eine entscheidende Rolle in der globalen Wirtschaft, indem sie wesentliche Rohstoffe für Branchen bereitstellen und als Absicherung gegen Inflation und wirtschaftliche Unsicherheit dienen. Die Rohstoffpreise werden von Faktoren wie Angebot und Nachfrage, geopolitischen Ereignissen und Wetterbedingungen beeinflusst.",
        "Bei Eurobitx bieten wir eine umfassende Palette von Rohstoffhandelsdienstleistungen, die darauf abzielen, Händlern zu helfen, Marktchancen zu nutzen und Risiken effektiv zu managen. Unsere Plattform bietet Zugang zu einer breiten Palette von Rohstoffen, einschließlich Edelmetallen, Energieprodukten und landwirtschaftlichen Rohstoffen, was es Händlern ermöglicht, gut diversifizierte Portfolios aufzubauen.",
        "Unser Team erfahrener Analysten bringt jahrzehntelange Expertise mit, um Einblicke in Trends am Rohstoffmarkt, Handelsempfehlungen und Risikomanagementstrategien zu bieten. Egal, ob Sie ein Einsteiger-Trader oder ein erfahrener Investor sind, wir bieten die Werkzeuge, Ressourcen und Unterstützung, die benötigt werden, um in der dynamischen Welt des Rohstoffhandels erfolgreich zu sein. Begleiten Sie uns und entdecken Sie das Potenzial der Rohstoffmärkte, um Ihre finanziellen Ziele zu erreichen.",
      ],
      img: commoditiesBg,
    },
    {
      heading: "CFDs",
      paragraph: [
        "Contracts for Difference (CFDs) sind derivative Finanzinstrumente, die es Händlern ermöglichen, auf Preisbewegungen in verschiedenen Finanzmärkten zu spekulieren, ohne die zugrunde liegenden Vermögenswerte zu besitzen. Sie bieten Flexibilität und Hebelwirkung und sind daher bei Händlern beliebt, die nach kurzfristigen Handelsmöglichkeiten und Portfoliodiversifikation suchen. Die CFD-Preise leiten sich vom Wert des zugrunde liegenden Vermögenswerts ab und werden mit Marge gehandelt.",
        "Bei Eurobitx bieten wir eine umfassende Palette von CFD-Handelsdienstleistungen, die darauf abzielen, die vielfältigen Bedürfnisse von Händlern zu erfüllen. Unsere Plattform bietet Zugang zu einer breiten Palette von CFD-Produkten, darunter Aktien, Indizes, Währungen, Rohstoffe und Kryptowährungen, was es Händlern ermöglicht, ihre Portfolios zu diversifizieren und Marktchancen zu nutzen.",
        "Unser Team erfahrener Fachleute bringt jahrzehntelange Expertise mit, um Einblicke in Markttrends, Handelsstrategien und Risikomanagementtechniken zu bieten. Egal, ob Sie ein Einsteiger-Trader oder ein erfahrener Investor sind, wir bieten die Unterstützung und Anleitung, die benötigt werden, um die Komplexitäten des CFD-Handels effektiv zu bewältigen. Begleiten Sie uns und entdecken Sie das Potenzial der CFD-Märkte, um Ihre finanziellen Ziele zu erreichen.",
      ],
      img: cfdsBg,
    },
    {
      heading: "Futures",
      paragraph: [
        "Die Futures-Märkte sind Märkte, auf denen Teilnehmer standardisierte Verträge handeln, um einen Vermögenswert zu einem festgelegten Preis zu einem bestimmten zukünftigen Datum zu kaufen oder zu verkaufen. Diese Verträge umfassen in der Regel Rohstoffe wie Öl, Gold und landwirtschaftliche Produkte sowie Finanzinstrumente wie Aktienindizes und Währungen. Der Handel mit Futures ermöglicht es Anlegern, sich gegen Preisschwankungen abzusichern und auf zukünftige Preisbewegungen zu spekulieren.",
        "Bei Eurobitx bieten wir eine umfassende Palette von Futures-Handelsdienstleistungen, die darauf abzielen, die Bedürfnisse von Tradern und Investoren zu erfüllen. Unsere Plattform bietet Zugang zu einer breiten Palette von Futures-Kontrakten, die verschiedene Anlageklassen und Märkte abdecken. Mit unseren fortschrittlichen Handelswerkzeugen und Echtzeit-Marktdaten können Trader Marktchancen nutzen und Risiken effektiv managen.",
        "Unser Team erfahrener Fachleute bringt jahrzehntelange Expertise mit, um Einblicke in Markttrends, Handelsstrategien und Risikomanagementtechniken zu bieten. Egal, ob Sie ein Einsteiger-Trader oder ein erfahrener Investor sind, wir bieten die Unterstützung und Anleitung, die benötigt werden, um die Komplexitäten des Handels mit Futures effektiv zu bewältigen. Begleiten Sie uns und entdecken Sie das Potenzial der Futures-Märkte, um Ihre finanziellen Ziele zu erreichen.",
      ],
      img: futuresBg,
    },
    {
      heading: "Krypto",
      paragraph: [
        "Die Kryptomärkte sind Orte, an denen Teilnehmer verschiedene Kryptowährungen wie Bitcoin, Ethereum und Altcoins handeln. Diese Märkte sind rund um die Uhr geöffnet und bieten Möglichkeiten für Trader und Investoren, digitale Assets zu kaufen, zu verkaufen und auszutauschen. Der Kryptomarkt ist bekannt für seine hohe Volatilität und sein Potenzial für signifikante Preisbewegungen, was für die Teilnehmer sowohl Risiken als auch Chancen birgt.",
        "Bei Eurobitx bieten wir eine umfassende Palette von Dienstleistungen, die auf die Bedürfnisse von Krypto-Tradern und -Investoren zugeschnitten sind. Unsere Plattform bietet Zugang zu einer breiten Palette von Kryptowährungen und ermöglicht es den Nutzern, Paare wie BTC/USD, ETH/BTC und viele andere zu handeln. Mit unserer intuitiven Handelsoberfläche und fortschrittlichen Analysetools können Trader effizient im Kryptomarkt navigieren und ihre Strategien effektiv umsetzen.",
        "Gestützt auf ein Team erfahrener Fachleute, die tief in der Kryptoindustrie verwurzelt sind, bieten wir wertvolle Einblicke in Marktdynamik, Handelstechniken und Risikomanagementstrategien. Egal, ob Sie neu im Kryptobereich sind oder ein erfahrener Investor, wir bieten die Unterstützung und Ressourcen, die für den Erfolg in der schnelllebigen Welt des Kryptohandels erforderlich sind. Begleiten Sie uns und nutzen Sie das Potenzial der Kryptomärkte, um Ihre finanziellen Ziele zu erreichen.",
      ],
      img: cryptoBg,
    },
  ],
};
