import Footer from "../layouts/FooterPages"
import Header from "../layouts/Header"
import SecOurServices from "../template/HomeDemo1/SecOurServices"
import {service_single_content}  from "../data/data-containers/data-HomeDemo1";
import { useContext } from "react";
import { LanguageContext } from "../languagesContext";
const Services = ()=>{
    const {language} = useContext(LanguageContext)
    return (
        <div style={{marginTop:"100px"}}>
            <Header/>
            <SecOurServices data={service_single_content[language]}/>
            <Footer/>
        </div>
    )
}
export default Services