import Footer from "../layouts/FooterPages";
import Header from "../layouts/Header";
import SectionHeading from "../components/SectionHeading";
import { toast } from "react-toastify";
import { apiUrl } from "../config";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../languagesContext";
import { languages } from "../languages";
const Signup = () => {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = languages[language];
  const [formData, setFormData] = useState([
    {
      id: "email",
      label: selectedLanguage.All.Email,
      placeholder: selectedLanguage.All.Email,
      type: "text",
      error: "",
      value: "",
    },
    {
      id: "password",
      label: selectedLanguage.All.Password,
      placeholder: selectedLanguage.All.Password,
      type: "password",
      error: "",
      value: "",
    },
    {
      id: "last_name",
      label: selectedLanguage.All.LastName,
      placeholder: selectedLanguage.All.LastName,
      type: "text",
      error: "",
      value: "",
    },
    {
      id: "first_name",
      label: selectedLanguage.All.FirstName,
      placeholder: selectedLanguage.All.FirstName,
      type: "text",
      error: "",
      value: "",
    },
    {
      id: "address",
      label: selectedLanguage.All.Address,
      placeholder: selectedLanguage.All.Address,
      type: "text",
      error: "",
      value: "",
    },
    {
      id: "postal_code",
      label: selectedLanguage.All.PostalCode,
      placeholder: selectedLanguage.All.PostalCode,
      type: "number",
      error: "",
      value: "",
    },
    {
      id: "phone",
      label: selectedLanguage.All.Phone,
      placeholder: selectedLanguage.All.Phone,
      type: "number",
      error: "",
      value: "",
    },
  ]);
  const [registerData, setRegisterData] = useState({});
  const [countryCode, setCountryCode] = useState();
  const [currencyId, setCurrencyId] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setFormData([
      {
        id: "email",
        label: selectedLanguage.All.Email,
        placeholder: selectedLanguage.All.Email,
        type: "text",
        error: "",
        value: "",
      },
      {
        id: "password",
        label: selectedLanguage.All.Password,
        placeholder: selectedLanguage.All.Password,
        type: "password",
        error: "",
        value: "",
      },
      {
        id: "last_name",
        label: selectedLanguage.All.LastName,
        placeholder: selectedLanguage.All.LastName,
        type: "text",
        error: "",
        value: "",
      },
      {
        id: "first_name",
        label: selectedLanguage.All.FirstName,
        placeholder: selectedLanguage.All.FirstName,
        type: "text",
        error: "",
        value: "",
      },
      {
        id: "address",
        label: selectedLanguage.All.Address,
        placeholder: selectedLanguage.All.Address,
        type: "text",
        error: "",
        value: "",
      },
      {
        id: "postal_code",
        label: selectedLanguage.All.PostalCode,
        placeholder: selectedLanguage.All.PostalCode,
        type: "number",
        error: "",
        value: "",
      },
      {
        id: "phone",
        label: selectedLanguage.All.Phone,
        placeholder: selectedLanguage.All.Phone,
        type: "number",
        error: "",
        value: "",
      },
    ]);
  }, [language]);
  useEffect(() => {
    const myHeaders = new Headers();
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/register-data`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setRegisterData(result);
      })
      .catch((error) => console.error(error));
  }, []);
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return { ...item, value: value, error: "" };
        }
        return item;
      });
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = formData.filter((item) => item.value === "");
    if (errors.length > 0) {
      setFormData(
        formData.map((item) => ({
          ...item,
          error: errors.includes(item) && "Field is required",
        }))
      );
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const getValueWIthId = (id) => {
      return formData.find((item) => item.id === id).value;
    };
    const raw = JSON.stringify({
      email: getValueWIthId("email"),
      password: getValueWIthId("password"),
      last_name: getValueWIthId("last_name"),
      first_name: getValueWIthId("first_name"),
      postal_code: getValueWIthId("postal_code"),
      phone: getValueWIthId("phone"),
      ref_country_id: countryCode,
      currency: currencyId,
      agree: true,
      address: getValueWIthId("address"),
      promo_code: "0",
      security_question: "dumb",
      security_answer: "answer",
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);
    fetch(`${apiUrl}/register`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        if (result.token) {
          toast.success("Registered");
          history.push("../login");
        } else if (result.error) {
          setErrorMessage(result.error);
          return;
        } else if (result.data) {
          setErrorMessage(result.data);
          return;
        } else {
          setErrorMessage(result);
        }
      })
      .catch((error) => {
        setErrorMessage(error);
        setErrorMessage("something went wrong");
        setLoading(false);
      });
  };
  return (
    <div style={{ marginTop: "120px" }}>
      <Header />
      <div className="contact_us_area" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionHeading title={selectedLanguage.All.SignUp} />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="contact_form">
                <div className="row">
                  {formData
                    ? formData.map((item) => (
                        <div
                          className="col-12 col-md-12"
                          data-aos="fade-up"
                          key={item.id}
                        >
                          <div
                            className="group wow fadeInUp"
                            data-wow-delay="0.2s"
                          >
                            <input
                              type={item.type}
                              name={item.id}
                              id={item.id}
                              placeholder={item.placeholder}
                              onChange={handleInputChange}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label>{item.label}</label>
                          </div>
                          <p style={{ color: "#bf6159" }}>{item.error}</p>
                        </div>
                      ))
                    : null}
                  <div className="col-12 col-md-6">
                    <div className="group wow fadeInUp">
                      <select
                        className="form-control"
                        onChange={(event) => setCountryCode(event.target.value)}
                        style={{
                          backgroundColor: "transparent",
                          color: "6C4E31",
                        }}
                      >
                        <option>{selectedLanguage.All.Country}</option>
                        {registerData.countries ? (
                          registerData.countries.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))
                        ) : (
                          <option>{selectedLanguage.All.Country}</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div>
                      <select
                        className="form-control"
                        style={{
                          backgroundColor: "transparent",
                          color: "#6C4E31",
                        }}
                        onChange={(event) => setCurrencyId(event.target.value)}
                      >
                        <option>{selectedLanguage.All.Currency}</option>
                        {registerData.currencies ? (
                          registerData.currencies.map((item) => (
                            <option key={item.id} value={item.code}>
                              {item.title}
                            </option>
                          ))
                        ) : (
                          <option>{selectedLanguage.All.Currency}</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div id="success_fail_info" />
                  </div>
                  <div
                    className="col-12 text-center wow fadeInUp"
                    data-wow-delay="0.6s"
                  >
                    <button
                      type="submit"
                      className="btn dream-btn"
                      data-aos="fade-up"
                      onClick={handleSubmit}
                    >
                      {loading
                        ? selectedLanguage.All.Loading
                        : selectedLanguage.All.Register}
                    </button>
                    <p style={{ color: "#bf6159" }}>{errorMessage}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Signup;
